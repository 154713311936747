const id = {
  menuSettingAccount: "Pengaturan Akun",
  settingAccountTitle: "Pengaturan Akun",
  settingAccountSubTitle: "Update profil dan keamanan akun kamu",
  settingAccountUpdateButton: "Ubah",
  settingAccountDetailEmailLabel: "Email",
  settingAccountDetailPasswordLabel: "Password",
  settingAccountSelfChangePasswordPreviousPageButton: "Kembali",
  settingAccountSelfChangePasswordCurrentPasswordLabel:
    "Masukan password saat ini",
  settingAccountSelfChangePasswordNewPasswordLabel: "Masukan password baru",
  settingAccountSelfChangePasswordBannerInfoMessage:
    "Setelah mengubah password, kamu akan otomatis keluar dari akun Kokatto dan perlu masuk ulang.",
  settingAccountSelfChangePasswordConfirmButton: "Konfirmasi",
  settingAccountSelfChangePasswordOldPasswordPlaceholder: "Password",
  settingAccountSelfChangePasswordNewPasswordPlaceholder: "Password baru",
  settingAccountSelfChangePasswordNewPasswordConfirmPlaceholder:
    "Konfirmasi password baru",
  settingAccountSelfChangePasswordErrorGeneral:
    "Terjadi kesalahan. Silakan coba lagi.",
  settingAccountSelfChangePasswordErrorOldPasswordMismatch:
    "Password saat ini salah",
  settingAccountSelfChangePasswordErrorConfirmPasswordMismatch:
    "Password baru dan konfirmasi password tidak cocok",
  settingAccountSelfChangePasswordErrorPasswordWrongFormat:
    "Password harus 8 karakter, alfanumerik, dan boleh menyertakan karakter spesial",
  settingAccountSelfChangePasswordErrorOldPasswordEmpty:
    "Password saat ini tidak boleh kosong.",
  settingAccountSelfChangePasswordErrorNewPasswordEmpty:
    "Password baru tidak boleh kosong",
  settingAccountSelfChangePasswordErrorConfirmPasswordEmpty:
    "Konfirmasi password harus diisi",
  settingAccountSelfChangePasswordErrorPasswordUsedRecently:
    "Password ini sudah pernah digunakan.",
  settingAccountSelfChangePasswordErrorPasswordPolicyGeneral:
    "Password tidak memenuhi persyaratan kebijakan",
  settingAccountSelfChangePasswordSuccessMessage:
    "Password berhasil diubah. Silahkan coba masuk lagi",
  forgotPasswordInvalidEmail: "Email tidak valid",
  forgotPasswordInputReceiverTitle: "Lupa password?",
  forgotPasswordInputReceiverDescription:
    "Masukkan email yang terhubung di akun {0} kamu untuk menerima kode OTP.",
  forgotPasswordInputReceiverEmailPlaceholder: "Email",
  forgotPasswordButtonLabelNext: "Lanjut",
  forgotPasswordInputOTPTitle: "Masukkan kode OTP",
  forgotPasswordInputOTPDescription: "Masukkan kode yang dikirim via email ke:",
  forgotPasswordInputOTPCountdownLabel: "Kirim kode baru dalam ",
  forgotPasswordInputOTPCountdownSeconds: "{0} detik",
  forgotPasswordInputOTPResendCode: "Kirim kode baru",
  forgotPasswordInputPasswordTitle: "Buat password baru",
  forgotPasswordInputPasswordDescription:
    "Masukkan password yang akan kamu gunakan untuk masuk ke akun {0}.",
  forgotPasswordInputPasswordFieldPasswordPlaceholder: "Password baru",
  forgotPasswordInputPasswordFieldConfirmPasswordPlaceholder:
    "Konfirmasi password baru",
  forgotPasswordButtonLabelConfirm: "Konfirmasi",
  forgotPasswordErrorUnregisterEmail: "Email tidak terdaftar",

  forgotPasswordErrorTooManyRequest: "Too many request",
  forgotPasswordErrorUserInactive: "Pengguna tidak aktif atau telah dihapus",
  forgotPasswordErrorInputReceiverErrorgeneral:
    "Terjadi kesalahan. Silakan coba lagi.",
  forgotPasswordErrorOTPExpired: "OTP sudah kedaluwarsa.",
  forgotPasswordErrorOTPIncorrect: "OTP yang dimasukkan salah.",
  forgotPasswordErrorInputOTPErrorgeneral:
    "Terjadi kesalahan. Silakan coba lagi.",
  forgotPasswordErrorInputOTPErrorRequired: "Harus diisi",
  forgotPasswordErrorTokenExpired:
    "Sesi waktu telah habis. Silahkan coba kembali",
  forgotPasswordErrorInputPasswordErrorgeneral:
    "Terjadi kesalahan. Silakan coba lagi.",
  forgotPasswordSaveSuccess:
    "Berhasil ubah password. Silakan masuk ke akun kamu.",

  accountVerificationTitle: "Verifikasi akun",
  enterVerificationFromYourAuthenticator:
    "Masukkan kode dari aplikasi Authenticator kamu:",
  verificationButtonText: "Verifikasi",
  verificationCodeIsInvalidErrorMessage:
    "Kode yang kamu masukkan salah. Silakan coba lagi.",

  accountSecurityTitle: "Keamanan akun",
  accountSecuritySubtitle: "Kelola cara login untuk melindungi akun kamu",
  twoFactorVerification: "Verifikasi 2 Langkah",
  twoFactorVerificationEnable: "Aktifkan",
  twoFactorVerificationDisable: "Nonaktifkan",
  loginWithGoogleSSO: "Masuk dengan Google (SSO)",
  connectGoogleSSO: "Hubungkan",

  MFASetupPageTitle: "Cara mengaktifkan Verifikasi 2 Langkah",
  howToEnable2FAStep1:
    "Instal {0} (contoh: Google Authenticator) di handphone kamu.",
  applicationAuthenticator: "aplikasi authenticator",
  howToEnable2FAStep2: "Buka aplikasi lalu {0} di bawah ini.",
  scanQRCode: "scan QR code",
  input6DigitCode: "Masukkan 6 digit kode verifikasi",
  howToEnable2FAStep3:
    "{0} yang muncul di aplikasi untuk verifikasi di bawah ini.",
  verificationIsActive: "Aktif",
  verificationIsDisable: "Nonaktifkan",
  disable2FASnackbar: "Berhasil menonaktifkan Verifikasi 2 Langkah.",
  enable2FASnackbar: "Berhasil mengaktifkan Verifikasi 2 Langkah.",
  multiClientSelectionTitle: "Pilih Akun",
  multiClientSelectionErrorMessage: "Gagal mengambil data",
  multiClientSelectionEmptyList: "Belum ada data",
  multiClientRetryButton: "Muat ulang",
  multiClientSuccessChangeClientMsg:
    "Berhasil mengganti client, silahkan melakukan login ulang",
  multiClientFailedChangeClientMsg: "Gagal mengganti client {0}",
};

const en = {
  menuSettingAccount: "Account",
  settingAccountTitle: "Account Settings",
  settingAccountSubTitle: "Update your profile and account security",
  settingAccountUpdateButton: "Edit",
  settingAccountDetailEmailLabel: "Email",
  settingAccountDetailPasswordLabel: "Password",
  settingAccountSelfChangePasswordPreviousPageButton: "back",
  settingAccountSelfChangePasswordCurrentPasswordLabel:
    "Enter current password",
  settingAccountSelfChangePasswordNewPasswordLabel: "Enter new password",
  settingAccountSelfChangePasswordBannerInfoMessage:
    "After changing password, you will be logged out automatically from Kokatto and need to re-sign.",
  settingAccountSelfChangePasswordConfirmButton: "Confirm",
  settingAccountSelfChangePasswordOldPasswordPlaceholder: "Password",
  settingAccountSelfChangePasswordNewPasswordPlaceholder: "New Password",
  settingAccountSelfChangePasswordNewPasswordConfirmPlaceholder:
    "Confirm New Password",
  settingAccountSelfChangePasswordErrorGeneral:
    "An error occurred. Please try again.",
  settingAccountSelfChangePasswordErrorOldPasswordMismatch:
    "Current password is incorrect",
  settingAccountSelfChangePasswordErrorConfirmPasswordMismatch:
    "New password and confirm password don't match",
  settingAccountSelfChangePasswordErrorPasswordWrongFormat:
    "Password must be 8 characters long, alphanumeric, and may include special characters",
  settingAccountSelfChangePasswordErrorOldPasswordEmpty:
    "Current password can’t be empty.",
  settingAccountSelfChangePasswordErrorNewPasswordEmpty:
    "New password can’t be empty.",
  settingAccountSelfChangePasswordErrorConfirmPasswordEmpty:
    "Password confirmation need to be filled",
  settingAccountSelfChangePasswordErrorPasswordUsedRecently:
    "This password has already been used.",
  settingAccountSelfChangePasswordErrorPasswordPolicyGeneral:
    "Password doesn’t meet policy requirements.",
  settingAccountSelfChangePasswordSuccessMessage:
    "Password succesfully changed. Please re-login",
  forgotPasswordInvalidEmail: "Invalid email",
  forgotPasswordInputReceiverTitle: "Forgot password?",
  forgotPasswordInputReceiverDescription:
    "Enter email connected to your {0} account to receive OTP code.",
  forgotPasswordInputReceiverEmailPlaceholder: "Email",
  forgotPasswordButtonLabelNext: "Next",
  forgotPasswordInputOTPTitle: "Enter OTP Code",
  forgotPasswordInputOTPDescription: "Code has been sent via email to:",
  forgotPasswordInputOTPCountdownLabel: "Send new code in ",
  forgotPasswordInputOTPCountdownSeconds: "{0} seconds",
  forgotPasswordInputOTPResendCode: "Send new code",
  forgotPasswordInputPasswordTitle: "Create new password",
  forgotPasswordInputPasswordDescription:
    "Enter password that you will use to sign to {0} account.",
  forgotPasswordInputPasswordFieldPasswordPlaceholder: "New password",
  forgotPasswordInputPasswordFieldConfirmPasswordPlaceholder:
    "Retype your new password",
  forgotPasswordButtonLabelConfirm: "Confirm",
  forgotPasswordErrorUnregisterEmail: "Email not registered",

  forgotPasswordErrorTooManyRequest: "Too many request",
  forgotPasswordErrorUserInactive: "User is inactive or has been deleted",
  forgotPasswordErrorInputReceiverErrorgeneral:
    "An error occurred. Please try again",
  forgotPasswordErrorOTPExpired: "OTP has expired",
  forgotPasswordErrorOTPIncorrect: "Incorrect OTP",
  forgotPasswordErrorInputOTPErrorgeneral:
    "An error occurred. Please try again",
  forgotPasswordErrorInputOTPErrorRequired: "Required",
  forgotPasswordErrorTokenExpired: "Session timed out. Please try again.",
  forgotPasswordErrorInputPasswordErrorgeneral:
    "An error occurred. Please try again",
  forgotPasswordSaveSuccess:
    "Successfully changed password. Please sign in to your account.",

  accountVerificationTitle: "Account verification",
  enterVerificationFromYourAuthenticator:
    "Enter code generated by your authenticator app:",
  verificationButtonText: "Verify",
  verificationCodeIsInvalidErrorMessage:
    "The code you entered is invalid. Please try again.",

  accountSecurityTitle: "Account Security",
  accountSecuritySubtitle: "Manage login methods to protect your account",
  twoFactorVerification: "2-Factor Verification",
  twoFactorVerificationEnable: "Enable",
  twoFactorVerificationDisable: "Disable",
  loginWithGoogleSSO: "Login with Google (SSO)",
  connectGoogleSSO: "Connect",

  MFASetupPageTitle: "How to enable 2-Factor Authentication",
  howToEnable2FAStep1:
    "Install {0} (example: Google Authenticator) on your handphone.",
  applicationAuthenticator: "authenticator app",
  howToEnable2FAStep2: "Open the app then {0} below.",
  scanQRCode: "scan QR code",
  input6DigitCode: "Enter the 6 digit verification code",
  howToEnable2FAStep3: "{0} displayed in the app for verification below.",
  verificationIsActive: "Enable",
  verificationIsDisable: "Disable",
  disable2FASnackbar: "Successfully disabled 2-Factor Authentication.",
  enable2FASnackbar: "Successfully enabled 2-Factor Authentication.",
  multiClientSelectionTitle: "Account Selection",
  multiClientSelectionErrorMessage: "Failed fetching data",
  multiClientSelectionEmptyList: "No data available",
  multiClientRetryButton: "Retry",
  multiClientSuccessChangeClientMsg:
    "Succesfully change client, please relogin",
  multiClientFailedChangeClientMsg: "Failed change client {0}",
};

export default { id, en };
